<template>
  <div
    class="modal fade"
    ref="HelpCategoryModal"
    id="HelpCategoryModal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <div class="modal-content">
        <Form
          class="form"
          id="HelpCategoryModal_form"
          :validation-schema="validationSchema"
          @submit="submit"
        >
          <div class="modal-header" id="HelpCategoryModal_header">
            <h2>{{ title }}</h2>
            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-1">
                <inline-svg src="media/icons/duotone/Navigation/Close.svg" />
              </span>
            </div>
          </div>
          <div class="modal-body py-10 px-lg-17">
            <div
              class="scroll-y me-n7 pe-7"
              id="HelpCategoryModal_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#HelpCategoryModal_header"
              data-kt-scroll-wrappers="#HelpCategoryModal_scroll"
              data-kt-scroll-offset="300px"
            >
              <div class="row mb-5">
                <div class="col-md-12 fv-row">
                  <label class="required fs-5 fw-bold mb-2">Name</label>
                  <Field
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="Enter name"
                    name="name"
                    v-model="helpCategoryData.name"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="name" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 mt-3 col-12">
                  <label for="description" class="form-label"
                    >Description</label
                  >
                  <textarea
                    v-model="helpCategoryData.description"
                    class="form-control"
                    id="description"
                    rows="3"
                  ></textarea>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 mt-3 col-12">
                  <label for="rolesDropdown" class="form-label">Role</label>
                  <RolesDropdown
                    id="rolesDropdown"
                    v-model="helpCategoryData.target_roles"
                  ></RolesDropdown>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer flex-center">
            <button
              type="reset"
              id="HelpCategoryModal_cancel"
              class="btn btn-white me-3"
              data-bs-dismiss="modal"
            >
              Discard
            </button>
            <button
              type="submit"
              id="HelpCategoryModal_submit"
              class="btn btn-primary"
            >
              <span class="indicator-label"> Submit </span>
              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import RolesDropdown from "@/components/dropdown/RolesDropdown";
import { Modal } from 'bootstrap';

function initialHelpCategoryData() {
  return {
        name: "",
        description: "",
    // eslint-disable-next-line @typescript-eslint/camelcase
        target_roles: "",
}};

export default defineComponent({
  name: "HelpCategoryModal",
  components: {
    ErrorMessage,
    Field,
    Form,
    RolesDropdown
  },
  props: {
    currentlySelectedHelpCategory: Object,
    action : String,
    title : String
  },
  mounted() {
    this.modal = new Modal(this.$refs.HelpCategoryModal)
  },
  data() {
    return {
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required()
        .label("Name")
    }),
      helpCategoryData: initialHelpCategoryData(),
      modal: null
    };
  },
  methods: {
    submit() {
      this.validationSchema.isValid(this.helpCategoryData).then((validationResult) => {
        if(validationResult){
          this.modal.hide();
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.$emit("saveChanges", {payload : this.helpCategoryData , action : this.action});
        }
      })
    },
  },
  watch: {
    currentlySelectedHelpCategory() {
      if(this.action == "edit" ){
      this.helpCategoryData.name = this.currentlySelectedHelpCategory.name
      this.helpCategoryData.description = this.currentlySelectedHelpCategory.description
        // eslint-disable-next-line @typescript-eslint/camelcase
      this.helpCategoryData.target_roles = this.currentlySelectedHelpCategory.target_roles.map(i => i.id);
      }
    },
    title() {
      if(this.action !== "edit") this.helpCategoryData = initialHelpCategoryData();
    }
 }
});
</script>
