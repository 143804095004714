<template>
  <!--begin::HelpCategoriesView-->
  <div class="row gy-5 g-xl-8">
    <HelpCategoriesIndex widget-classes="card-xxl-stretch mb-5 mb-xl-8"></HelpCategoriesIndex>
  </div>
  <!--end::HelpCategoriesView-->
</template>

<script lang="js">
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import HelpCategoriesIndex from "@/components/help/HelpcategoriesIndex.vue";

export default defineComponent({
  name: "HelpCategoriesView",
  components: {
    HelpCategoriesIndex
  },
  mounted() {
    setCurrentPageTitle("Help Categories");
  }
});
</script>
