<template>
  <div class="modal fade" id="ArticlesModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog mw-650px">
      <div class="modal-content">
        <div class="modal-header pb-0 border-0 justify-content-end">
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotone/Navigation/Close.svg" />
            </span>
          </div>
        </div>
        <div class="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
          <div class="text-center mb-13">
            <h1 class="mb-3">View articles</h1>
            <div class="text-gray-400 fw-bold fs-5">
              Showing articles for category:
              {{ relatedHelpCategory.name }}
            </div>
          </div>
          <table
            class="
            table table-row-dashed table-row-gray-300
            align-middle
            gs-0
            gy-4
          "
          >
            <thead>
              <tr class="fw-bolder text-muted">
                <th style="width: 70%">Name</th>
                <th style="width: 30%">Created By</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(article, index) in relatedHelpCategory.articles"
                :key="index"
              >
                <td
                  @click="openInfoModal(article.text)"
                  data-bs-dismiss="modal"
                >
                  <span
                    class="text-dark fw-bolder text-hover-primary d-block fs-6"
                    style="cursor: pointer;"
                    ><span
                      v-if="article.text"
                      class="svg-icon svg-icon-primary me-4"
                    >
                      <span class="svg-icon svg-icon-primary svg-icon-2x"
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24" />
                            <circle
                              fill="#000000"
                              opacity="0.3"
                              cx="12"
                              cy="12"
                              r="10"
                            />
                            <rect
                              fill="#000000"
                              x="11"
                              y="10"
                              width="2"
                              height="7"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              x="11"
                              y="7"
                              width="2"
                              height="2"
                              rx="1"
                            />
                          </g></svg
                        ></span
                      ></span
                    >
                    <span>{{
                      article.title
                    }}</span></span
                  >
                </td>
                <td>
                  <span
                    v-if="article.created_by"
                    class="text-dark fw-bolder text-hover-primaryfs-6"
                    >{{ article.created_by.id }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ArticlesModal",
  components: {
  },
  props: {
    relatedHelpCategory: Object
  },
  methods: {
    openInfoModal(description) {
      if (description) {
        this.$emit("infoModalOpened", { title: "Description", description, width: "750"});
      } else {
        return;
      }
    }
  },
});
</script>
