<template>
  <div class="card" :class="widgetClasses">
    <HelpCategoryModal
      v-show="isThereAModalActive"
      :currentlySelectedHelpCategory="currentlySelectedHelpCategory"
      :title="modalSetup.title"
      :action="modalSetup.action"
      @saveChanges="saveDataFromModal"
    ></HelpCategoryModal>
    <ArticlesModal
      :relatedHelpCategory="currentlySelectedHelpCategory"
      @infoModalOpened="openInfoModal"
    ></ArticlesModal>
    <InfoModal
      v-show="isThereAModalActive"
      :setup="modalSetup"
      :width="customModalWidth"
      @close="resetInfoModalMessage"
    ></InfoModal>
    <DeleteModal
      v-show="isThereAModalActive"
      :currentlySelectedEntry="currentlySelectedHelpCategory.name"
      @delete="deleteSelectedHelpCategory"
    ></DeleteModal>
    <div class="card mb-5 mb-xl-8">
      <div class="card-header border-0 pt-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label fw-bolder fs-3 mb-1">Help Categories</span>
          <span class="text-muted mt-1 fw-bold fs-7"
            >List of Help Categories</span
          >
        </h3>
        <div class="d-flex align-items-center position-relative">
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <path
                  d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                  fill="#000000"
                  fill-rule="nonzero"
                  opacity="0.3"
                />
                <path
                  d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                  fill="#000000"
                  fill-rule="nonzero"
                />
              </g>
            </svg>
          </span>
          <div class="row">
            <div class="col">
              <RolesDropdown
                :multiple-roles="false"
                @change="updateSearchByRole"
              ></RolesDropdown>
            </div>
          </div>
        </div>
        <div
          class="card-toolbar"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-trigger="hover"
          title="Click to add a help category"
        >
          <a
            href="#"
            class="btn btn-sm btn-success"
            data-bs-toggle="modal"
            data-bs-target="#HelpCategoryModal"
            @click.prevent="openAddNewModal"
          >
            <span class="svg-icon svg-icon-3">
              <inline-svg src="media/icons/duotone/Code/Plus.svg" />
            </span>
            Add new Help Category
          </a>
        </div>
      </div>
      <div class="card-body py-3">
        <div class="d-flex justify-content-center " v-if="loading">
          <div
            class="spinner-border text-primary m-20 spinner-size-increase"
            role="status"
          ></div>
        </div>
        <el-empty
          v-else-if="helpCategoriesList.length == 0"
          :description="
            'OOPS... Unfortunately no help categories matching your filters were found.'
          "
        ></el-empty>
        <HelpCategoriesDataTable
          v-else
          @saveWeightsButtonIsClicked="handleSaveWeights"
          @articlesModalOpened="openArticlesModal"
          @infoModalOpened="openInfoModal"
          @editModalOpened="openEditModal"
          @deleteModalOpened="openDeleteModal"
          :tableData="helpCategoriesList"
        ></HelpCategoriesDataTable>
      </div>
      <Pagination
        :currentPage="pagination.current_page"
        :totalPages="pagination.last_page"
        @changeCurrentPage="changeSearchByPage"
      ></Pagination>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import HelpCategoryModal from "@/components/help/Modals/HelpcategoryModal";
import ArticlesModal from "@/components/help/Tables/ArticlesModal.vue";
import InfoModal from "@/components/general/InfoModal.vue";
import DeleteModal from "@/components/general/DeleteModal.vue";
import HelpCategoriesDataTable from "@/components/help/Tables/HelpcategoriesDataTable";
import RolesDropdown from "@/components/dropdown/RolesDropdown";
import Pagination from "@/components/pagination/Pagination.vue";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "HelpCategoriesIndex",
  components: {
    HelpCategoryModal,
    ArticlesModal,
    InfoModal,
    DeleteModal,
    HelpCategoriesDataTable,
    RolesDropdown,
    Pagination
  },
  props: {
    widgetClasses: String
  },
  data() {
    return {
      currentlySelectedHelpCategory: {
        id: null,
        name: null,
        articles: []
      },
      isThereAModalActive: false,
      modalSetup: {},
      pagination: {},
      query: {
        roleId: ""
      },
      customModalWidth: ""
    };
  },
  methods: {
    ...mapActions("HelpCategoriesModule", [
      "fetchData",
      "createNew",
      "update",
      "destroyData",
      "setQuery",
      "setPageNumber",
      "resetPageNumber",
      "resetState",
      "saveWeights"
    ]),
    updateSearchByRole(textInput) {
      this.query.roleId = textInput;
      this.setQuery(this.query);
      this.fetchData();
    },
    changeSearchByPage(pageNumber) {
      this.setPageNumber(pageNumber);
      this.fetchData();
    },
    openArticlesModal(relatedHelpCategory) {
      this.modalSetup.action = "articles";
      this.resetHelpCategoryData();
      this.currentlySelectedHelpCategory.id = relatedHelpCategory.id;
      this.currentlySelectedHelpCategory.name = relatedHelpCategory.name;
      this.currentlySelectedHelpCategory.articles =
        relatedHelpCategory.articles;
      const articleModal = new Modal(document.getElementById('ArticlesModal'));
      articleModal.show();
    },
    openAddNewModal() {
      this.resetHelpCategoryData();
      this.modalSetup.title = "Add new Help Category";
      this.modalSetup.action = "addnew";
      this.isThereAModalActive = true;
    },
    openEditModal(helpCategory) {
      this.modalSetup.title = "Edit Help Category";
      this.modalSetup.action = "edit";
      this.currentlySelectedHelpCategory = helpCategory;
    },
    saveDataFromModal(dataFromModal) {
      if (dataFromModal.action == "edit") {
        this.update({
          payload: dataFromModal.payload,
          id: this.currentlySelectedHelpCategory.id
        });
      } else {
        this.createNew(dataFromModal.payload);
      }
    },
    openDeleteModal(deleteModalPayload) {
      this.currentlySelectedHelpCategory = deleteModalPayload;
      this.isThereAModalActive = true;
    },
    openInfoModal(payload) {
      this.modalSetup = {
        title: payload.title,
        message: payload.description,
        width: payload.width ? payload.width : ""
      };
      this.isThereAModalActive = true;
      const infoModal = new Modal(document.getElementById('infoModal'));
      infoModal.show();
    },
    deleteSelectedHelpCategory() {
      this.destroyData(this.currentlySelectedHelpCategory.id);
      this.isThereAModalActive = false;
      this.currentlySelectedHelpCategory.id = null;
    },
    handleSaveWeights(params) {
      this.saveWeights(params);
    },
    resetHelpCategoryData() {
      this.currentlySelectedHelpCategory = {
        id: null,
        name: null
      };
    },
    resetInfoModalMessage() {
      const infoModal = new Modal(document.getElementById('infoModal'));
      infoModal.hide();
      this.modalSetup.message = "";
    }
  },
  computed: {
    ...mapGetters("HelpCategoriesModule", [
      "helpCategoriesList",
      "loading",
      "getPaginationData"
    ])
  },
  mounted() {
    this.fetchData();
  },
  updated() {
    this.pagination = this.getPaginationData;
  },
  unmounted() {
    this.resetState();
  }
});
</script>

<style>
.spinner-size-increase {
  width: 3.5rem;
  height: 3.5rem;
}
</style>
