
import { defineComponent } from "vue";

export default defineComponent({
  name: "InfoModal",
  props: {
    setup: {
      type: Object,
      require
    }
  }
});
