<template>
  <el-select
    v-model="value"
    clearable
    size="large"
    filterable
    placeholder="Select roles"
    :multiple="multipleRoles"
    class="w-100"
  >
    <el-option
      v-for="item in roles"
      :key="item.id"
      :label="item.title"
      :value="item.id"
    >
    </el-option>
  </el-select>
</template>
<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "RolesDropdown",
  props: {
    multipleRoles: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      value: ""
    };
  },
  methods: {
    ...mapActions("ListsModule", ["fetchRoles"])
  },
  mounted() {
    this.fetchRoles();
  },
  computed: {
    ...mapGetters("ListsModule", ["roles"])
  }
});
</script>
