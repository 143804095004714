
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    currentlySelectedEntry: String
  },
  methods: {
    deleteButtonClicked() {
      this.$emit("delete");
    }
  }
});
