import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "infoModal",
  "data-bs-keyboard": "false",
  "data-bs-backdrop": "static",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-header" }
const _hoisted_4 = {
  class: "modal-title",
  id: "infoModal"
}
const _hoisted_5 = { class: "svg-icon svg-icon-1" }
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["modal-dialog modal-dialog-centered", _ctx.setup.width ? 'mw-' + _ctx.setup.width + 'px' : ''])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.setup.title), 1),
          _createElementVNode("div", {
            class: "btn btn-sm btn-icon btn-active-color-primary",
            "data-bs-dismiss": "modal",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
          }, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotone/Navigation/Close.svg" })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("h2", {
            innerHTML: _ctx.setup.message
          }, null, 8, _hoisted_7)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-secondary",
            "data-bs-dismiss": "modal",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
          }, " Close ")
        ])
      ])
    ], 2)
  ]))
}