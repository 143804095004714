<template>
  <div class="table-responsive">
    <table
      class="
            table table-row-dashed table-row-gray-300
            align-middle
            gs-0
            gy-4
          "
    >
      <thead>
        <tr class="fw-bolder text-muted">
          <th style="width: 15%"></th>
          <th style="width: 15%">
            Weight
            <button class="btn btn-info btn-sm ms-1" @click="saveWeights">
              Save
            </button>
          </th>
          <th class="min-w-lg-100px">Name</th>
          <th class="min-w-lg-100px">Role</th>
          <th class="min-w-120px text-end">Actions</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="helpCategory in tableData" :key="helpCategory.id">
          <td class="text-center">
            <a
              v-if="helpCategory.articles.length > 0"
              type="button"
              class="btn btn-sm btn-info"
              @click="showArticlesModal(helpCategory)"
              >View articles</a
            >
          </td>
          <td>
            <input
              type="number"
              @change="changeOrder($event, helpCategory.id)"
              v-model.trim="helpCategory.weight"
              class="form-control ps-5"
            />
          </td>
          <td
            @click="openInfoModal(helpCategory.description)"
          >
            <span
              class="text-dark fw-bolder text-hover-primary d-block fs-6"
              :style="helpCategory.description ? 'cursor: pointer;' : ''"
              ><span
                :style="
                  `visibility:${
                    helpCategory.description ? 'visible' : 'hidden'
                  }`
                "
                class="svg-icon svg-icon-primary me-4"
              >
                <span class="svg-icon svg-icon-primary svg-icon-2x"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <circle
                        fill="#000000"
                        opacity="0.3"
                        cx="12"
                        cy="12"
                        r="10"
                      />
                      <rect
                        fill="#000000"
                        x="11"
                        y="10"
                        width="2"
                        height="7"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        x="11"
                        y="7"
                        width="2"
                        height="2"
                        rx="1"
                      />
                    </g></svg
                  ></span
                ></span
              >
              <span>{{ helpCategory.name }}</span></span
            >
          </td>
          <td>
            <span
              class="badge rounded-pill bg-primary text-wrap ms-1 mb-2 "
              v-for="role in helpCategory.target_roles"
              :key="role.id"
            >
              {{ role.title }}
            </span>
          </td>
          <td class="text-end">
            <div class="btn-group btn-group-sm pb-2 pt-1" role="group">
              <button
                @click="emitEditEvent(helpCategory)"
                type="button"
                class="btn btn-sm btn-warning"
                data-bs-toggle="modal"
                data-bs-target="#HelpCategoryModal"
              >
                Edit
              </button>
              <button
                @click="openDeleteModal(helpCategory)"
                class="btn btn-sm btn-danger"
                data-bs-target="#deleteModal"
                data-bs-toggle="modal"
              >
                Delete
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "HelpCategoriesDataTable",

  props: {
    tableData: Object
  },

  data() {
    return {
      weightsToChange: []
    };
  },

  methods: {
    showArticlesModal(helpCategory) {
      this.$emit("articlesModalOpened", helpCategory);
    },
    emitEditEvent(helpCategory) {
      const editedHelpCategory = {
        id: helpCategory.id,
        name: helpCategory.name,
        description: helpCategory.description,
        // eslint-disable-next-line @typescript-eslint/camelcase
        target_roles: helpCategory.target_roles
      };

      this.$emit("editModalOpened", editedHelpCategory);
    },

    openDeleteModal(helpCategory) {
      this.$emit("deleteModalOpened", helpCategory);
    },

    openInfoModal(description) {
      if (description) {
        this.$emit("infoModalOpened", { title: "Description", description });
      } else {
        return;
      }
    },

    changeOrder(e, skillId) {
      const i = this.weightsToChange.findIndex(i => i.id === skillId);

      if (i > -1)
        this.weightsToChange[i] = { id: skillId, weight: e.target.value };
      else
        this.weightsToChange.push({
          id: skillId,
          weight: e.target.value
        });
    },

    saveWeights() {
      this.$emit("saveWeightsButtonIsClicked", {
        categories: this.weightsToChange
      });
    }
  }
});
</script>
